import React from "react";
import "./Notfound.css";

const Notfound = () => {
    return (
        <div>
            <img src="images/mafazlogomain.svg" alt=""/>
            <h1> WebSite Under Maintenance </h1>
        </div>
    );
};

export default Notfound;