import React from "react";
import "./Catalogue.css";

const Catalogue = () => {
    return (
        <div>
            <img src="images/mafazlogomain.svg" alt=""/>
            <h1> Catalogue Page Loading... </h1>
        </div>
    );
};

export default Catalogue;