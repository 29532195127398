import React, { useState } from "react";
import {Link, NavLink} from "react-router-dom";
import {GoThreeBars} from "react-icons/go";
import {MdOutlineClose} from "react-icons/md";
import styled from "styled-components";
import Head from "./Head";
import "./Navbar.css";

const Navbar = () => {

    const [isNavShowing, setIsNavShowing] = useState(false);

    const links = [
        {name:"Home", path:'/home'},
        {name:"About Us", path:'/aboutus'},
        {name:"Products", path:'/products'},
        {name:"Catalogue", path:'/catalogue'},
        {name:"Blog", path:'/blog'},
        {name:"Get a Quote", path:'/getaquote',}
    ];
    return (
        <>
        <nav>
            <div className="container nav__container">
                <Link to="/home" className="logo" onClick={() => setIsNavShowing(false)}>
                    <img src="/images/mafazlogosub.svg" alt="" />
                </Link>
                <ul className={`nav__links ${isNavShowing ? "show__nav" : "hide__nav"}`}>
                    {
                        links.map(({name, path}, index) => {
                            return (
                                <li key={index}>
                                    <NavLink to={path} className={({isActive}) => isActive ? 'active-nav' : ''}
                                    onClick={() => setIsNavShowing (prev => !prev)} >{name}</NavLink>
                                </li>
                            )
                        })
                    }
                </ul>
                <button className="nav__toggle-btn" onClick={() => setIsNavShowing (prev => !prev)}>
                    {
                        isNavShowing ? <MdOutlineClose/> : <GoThreeBars/>
                    }
                </button>
            </div>
        </nav>
        </>
    );
};


    

export default Navbar;