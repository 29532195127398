import React from "react";
import "./Getaquote.css";

const Getaquote = () => {
    return (
        <div>
            <img src="images/mafazlogomain.svg" alt=""/>
            <h1> Get a Quote Page Loading... </h1>
        </div>
    );
};

export default Getaquote;