import React from "react";
import "./Head.css";

const Head = () => {
  return (
    <>
      <section className='head'>
        <div className=' d_flex1'>
          <div className='left rowhead'>
                <a href="tel:+97333597575">
                    <img src="/images/phone-icon.svg" alt="" />
                    <span>+973 3359 7575</span>
                </a>
                <a href="mailto:info@mafazgroup.com.com">
                    <img src="/images/mail-icon.svg" alt="" />
                    <span>info@mafazgroup.com</span>
                </a>
          </div>
        </div>
      </section>
    </>
  )
}

export default Head
